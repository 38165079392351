<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:
          </div>
          <div id='tip'>
            Texto relevante
            <img src="@/assets/cryptoboard/DIA-PgFxqg0.png" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BAKE',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
